import React from 'react';
import { useParams } from 'react-router-dom';
import '../project-css/explore.css'; // Import the CSS file


function Explore() {
  const { serviceName } = useParams();

  return (
    <div className="explore-page">
      <h1>Projects {serviceName}</h1>
      <p>This section will be updated soon with more information about {serviceName}. Stay tuned!</p>
    </div>
  );
}

export default Explore;

  



