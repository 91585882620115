
// components/CustomMessage.js
import React from "react";

function CustomMessage({ status }) {
  const messages = {
    idle: null,
    uploading: "Uploading video...",
    success: "Video uploaded successfully!",
    error: "Error uploading video. Please try again.",
  };

  return <p className={`message message-${status}`}>{messages[status]}</p>;
}

export default CustomMessage;
