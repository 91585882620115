// components/Login.js
import React, { useState, useEffect } from 'react';
import VideoUpload from './videoUpload'; // Correct import for VideoUpload component
import './login.css'; // Import CSS file for login styling

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const loginDuration = 5 * 60 * 1000; // 5 minutes in milliseconds

  // Check if the user is already authenticated
  useEffect(() => {
    const storedLoginTime = localStorage.getItem('loginTime');
    if (storedLoginTime) {
      const currentTime = new Date().getTime();
      const loginTime = parseInt(storedLoginTime, 10);
      if (currentTime - loginTime < loginDuration) {
        setIsAuthenticated(true);
      } else {
        localStorage.removeItem('loginTime');
      }
    }
  }, [loginDuration]);

  const handleLogin = (e) => {
    e.preventDefault();
    // Static credentials for testing
    if (username === 'usman' && password === '440579') {
      setIsAuthenticated(true);
      setError('');
      localStorage.setItem('loginTime', new Date().getTime().toString());
    } else {
      setError('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('loginTime');
  };

  // Clear authentication status after 5 minutes
  useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => {
        handleLogout();
      }, loginDuration);

      return () => clearTimeout(timer);
    }
  }, [isAuthenticated, loginDuration]);

  return (
    <div className="login-container">
      {!isAuthenticated ? (
        <div className="login-form">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      ) : (
        <div>
          <VideoUpload isAuthenticated={isAuthenticated} /> {/* Show VideoUpload component when authenticated */}
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default Login;
