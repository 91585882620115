import React from 'react';
import { Link } from 'react-router-dom';

import '../Home-css/HomeHeroSec.css';

import VE from '../../assets/VE.webp';
import WA from '../../assets/WA.webp';
import WP from '../../assets/WP.webp';
import SW from '../../assets/SW.webp';
import LA from '../../assets/logo-animation.webp';
import PV from '../../assets/promotion.webp';

import '../Home-css/Home-services.css'; 

const HomeServices = React.forwardRef((props, servicesRef) => {
  return (
    <div id="services-section" className="skills-section" ref={servicesRef}>
      <h2>Our Services</h2>
      <div className="skills-grid">
        <div className="skills-row">
          <div className="skill-column">
            <div className="skill-content">
              <div className="skill-button">
                <img src={VE} alt="Motion Graphics" className="skill-image" />
                <Link to="/motion-graphics" className="btn-contact">Explore</Link>
              </div>
              <div className="skill-info">
                <h3>Motion Graphics</h3>
                <p>Create dynamic and engaging motion graphics with expert After Effects animation.</p>
              </div>
            </div>
          </div>
          <div className="skill-column">
            <div className="skill-content">
              <div className="skill-button">
                <img src={WA} alt="Web Application" className="skill-image" />
                <Link to="/explore/Web Application" className="btn-contact">Explore</Link>
              </div>
              <div className="skill-info">
                <h3>Web Application</h3>
                <p>Develop responsive and interactive web applications using the powerful React framework.</p>
              </div>
            </div>
          </div>
          <div className="skill-column">
            <div className="skill-content">
              <div className="skill-button">
                <img src={LA} alt="Logo Animation" className="skill-image" />
                <Link to="/motion-graphics" className="btn-contact">Explore</Link>
              </div>
              <div className="skill-info">
                <h3>Logo Animation</h3>
                <p>Bring your brand to life with captivating professional logo animations that leave a lasting impression</p>
              </div>
            </div>
          </div>
        </div>
        <div className="skills-row">
          <div className="skill-column">
            <div className="skill-content">
              <div className="skill-button">
                <img src={PV} alt="Promotional Video" className="skill-image" />
                <Link to="/promotional" className="btn-contact">Explore</Link>
              </div>
              <div className="skill-info">
                <h3>Promotional Video</h3>
                <p>Elevate your brand with stunning and engaging promotional videos designed to captivate your audience</p>
              </div>
            </div>
          </div>
          <div className="skill-column">
            <div className="skill-content">
              <div className="skill-button">
                <img src={WP} alt="WordPress eCommerce Store" className="skill-image" />
                <Link to="/explore/Wordpress" className="btn-contact">Explore</Link>
              </div>
              <div className="skill-info">
                <h3>WordPress eCommerce Store</h3>
                <p>Build and customize fully functional eCommerce stores with WordPress for seamless online sales.</p>
              </div>
            </div>
          </div>
          <div className="skill-column">
            <div className="skill-content">
              <div className="skill-button">
                <img src={SW} alt="SolidWorks" className="skill-image" />
                <Link to="/solidworks" className="btn-contact">Explore</Link>
              </div>
              <div className="skill-info">
                <h3>SolidWorks</h3>
                <p>Design and create precise 3D models with SolidWorks for engineering and manufacturing solutions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HomeServices;
