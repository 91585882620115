// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCKW8yp8RlQUBHk_OwFu1ojWZ5h5tIKqH4",
    authDomain: "manni-s-portfolio.firebaseapp.com",
    projectId: "manni-s-portfolio",
    storageBucket: "manni-s-portfolio.appspot.com",
    messagingSenderId: "563611218251",
    appId: "1:563611218251:web:70df8ef62070ec0199f8ce",
    measurementId: "G-5ZK3F0Z6S4"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);