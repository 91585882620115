import React from "react";


import '../Home-css/Personal-Info.css'

const WhoAmISection = ({ aboutMeRef, workExperienceRef }) => {
  return (
    <div className="who-am-i-section">
      <h2>Who Is Usman Sultan?</h2>
      <div className="who-am-i-content">
        <div className="about-me" ref={aboutMeRef}>
          <div className="about-me-section">
            <h2>About Me</h2>
            <p>
              Hello! I'm Usman Sultan, I have a diverse skill set spanning
              various programming languages, libraries, frameworks, and
              animation tools. Here’s a bit more about my expertise:
            </p>

            <div className="education">
              <h2>Education</h2>
              <p>
                I graduated with a degree in Computer Science from AIR
                University. During my time there, I developed an Ethereum
                blockchain web application as my final year project, which
                fueled my passion for blockchain development.
              </p>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoAmISection;
