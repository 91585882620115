import React from "react";
import '../Home-css/WorkExperience.css'

const WorkExperience = React.forwardRef((props, ref) => {
  return (
    <div className="work-experience-section">
      {/* Work Experience Section */}
      <div className="work-experience" ref={ref}>
        <h2>Work Experience</h2>
        <p>
          With over four years in animation and six months in web development, I
          specialize in creating compelling ads and promotional content. My work
          combines advanced tools like Adobe After Effects, Blender, and Spline to
          deliver striking 3D visuals and interactive web experiences. Whether you
          need eye-catching ads or a standout portfolio, my unique blend of skills
          can help bring your vision to life.
        </p>
      </div>

      {/* Skills Section */}
      <div className="skills">
        <h3>Programming Languages</h3>
        <ul>
          <li>C++</li>
          <li>JavaScript</li>
          <li>Solidity</li>
        </ul>
      </div>

      {/* Libraries Section */}
      <div className="libraries">
        <h3>Libraries</h3>
        <ul>
          <li>React.js</li>
          <li>Ethers.js</li>
        </ul>
      </div>

      {/* Frameworks Section */}
      <div className="frameworks">
        <h3>Frameworks</h3>
        <ul>
          <li>Node.js</li>
          <li>Hardhat.js</li>
        </ul>
      </div>

      {/* Animation Tools Section */}
      <div className="animation-tools">
        <h3>Animation Tools</h3>
        <ul>
          <li>Adobe After Effects</li>
          <li>Adobe Premiere Pro</li>
          <li>Adobe Photoshop</li>
          <li>Adobe Audition</li>
          <li>Three JS (currently learning)</li>
          <li>Spline (currently learning)</li>
        </ul>
      </div>
    </div>
  );
});

export default WorkExperience;
