import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import HomeHeroSec from './Home-component/HomeHeroSec';
import HomeServices from './Home-component/Home-services';
import PersonalInfo from './Home-component/Personal-info';
import WorkExperience from './Home-component/WorkExperience';

import '../css/Home.css';


function Home({ homeRef, servicesRef, aboutMeRef, workExperienceRef }) {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home" ref={homeRef}>
      <Helmet>
        <title>Usman Sultan - Animator, Web Developer & Blockchain Expert</title>
        <meta name="description" content="A dedicated professional skilled in animation, web development, and blockchain technology." />
        <link rel="stylesheet" href="../css/Home.css" />
      </Helmet>

      <div className="home-hero-section" ref={homeRef}>
        <HomeHeroSec />
      </div>

     

      <div id="services-section" className="skills-section" ref={servicesRef}>
        <HomeServices />
      </div>

      <div className="about-me" ref={aboutMeRef}>
      <PersonalInfo />
      </div>

      <div className="work-experience" ref={workExperienceRef}>
        <WorkExperience />
      </div>


   



      


      {showScrollTop && (
        <button
          className="scroll-to-top"
          onClick={scrollToTop}
          aria-label="Scroll to Top"
        >
          ↑
        </button>
      )}
    </div>
  );
}

export default Home;





