import React, { useState, useEffect } from "react";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../firebase";
import "../project-css/motionGraphics.css";
import Lottie from "react-lottie";
import animationData from '../assets/caterpillar.json'; // Use the Lottie animation file here

function MotionGraphics() {
  const [videoUrls, setVideoUrls] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosListRef = ref(storage, "solidworks/");
        const response = await listAll(videosListRef);
        const urls = await Promise.all(
          response.items.map(async (item) => {
            try {
              return await getDownloadURL(item);
            } catch (error) {
              console.error("Error getting download URL:", error);
              setError("Error getting download URL: " + error.message);
              return null;
            }
          })
        );
        setVideoUrls(urls.filter((url) => url !== null));
      } catch (error) {
        console.error("Error listing videos:", error);
        setError("Error listing videos: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="motion-graphics-container">
      {loading ? (
        <div className="preloader">
          <Lottie options={defaultOptions} height={100} width={200} />
        </div>
      ) : (
        <>
          {error && <p className="error-message">{error}</p>}
          <div className="video-container">
            {videoUrls.map((url, index) => (
              <div key={url} className="video-wrapper">
                <video src={url} controls />
                <p className="video-label">{`SW ${String(index + 1).padStart(3, '0')}`}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default MotionGraphics;
